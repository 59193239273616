import { render, staticRenderFns } from "./User-profile.vue?vue&type=template&id=fe1f2a98&scoped=true&"
import script from "./User-profile.vue?vue&type=script&lang=js&"
export * from "./User-profile.vue?vue&type=script&lang=js&"
import style0 from "./User-profile.vue?vue&type=style&index=0&id=fe1f2a98&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe1f2a98",
  null
  
)

export default component.exports